import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/config/configuration';
import { LocalStorageService } from '../../base/storage/storage.service';
import { RatingListResponse } from '../../admin/service/admin/admin.service';

@Injectable()
export class PurchaseService {

  private baseApiUrl: string;

  constructor(private http: HttpClient, private localStorage: LocalStorageService) {
    this.baseApiUrl = Configuration.getApiBaseUrl();
  }

  sell(eanIsbnData) {

    const path = '/api/web/purchase';
    const referer = this.localStorage.get('referer');
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { eanIsbnData, referer });
  }

  sellList(partner, eanIsbnData) {
    const path = '/api/web/purchase/list';
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { eanIsbnData, partner });
  }

  getBasket() {
    const path = '/api/web/purchase/get-basket';
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }

  deleteArticleTmp(articleId: number) {
    console.log('in delete');
    const path = '/api/web/purchase/delete-article-tmp';
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { articleId });
  }

  finishPurchase() {
    const path = '/api/web/purchase/finish';
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }

  getArticleData(ean: string) {
    const path = '/api/web/purchase/article-data';
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { ean });
  }



  getTopArticles() {
    const path = '/api/web/purchase/articles_top';
    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }

  /**
   */
  mapErrorMessage(remoteMessage: string, readyToSell = null) {

    let errorMessage: string;

    if (remoteMessage === 'No Product Found') {

      errorMessage = 'Schade, zu deinen eingegebenen Daten konnten wir kein Buch finden.';

    } else if (remoteMessage === 'Max Products achieved') {

      errorMessage = 'Die maximale Anzahl von Artikeln in deinem Ankaufskorb ist erreicht. ';
      if (readyToSell) {
        errorMessage += 'Schließe den Verkauf ab und beginne einen neuen Warenkorb.';
      } else {
        errorMessage += 'Du kannst Artikel mit einem geringen Ankaufswert durch andere Artikel ersetzen,' +
          ' um den Ankaufspreis zu erreichen.';
      }

    } else if (remoteMessage.startsWith('Max same articles reached')) {
      const m = remoteMessage.split('|');
      errorMessage = 'Schade, wir kaufen jeden gleichen Artikel nur ' + (m[1] ? m[1] : 2) + ' mal an.';
    } else if (remoteMessage === 'VOUCHER_NOT_AVAILABLE') {
      errorMessage = 'Schade. Wir konnten den Gutschein nicht finden.';
    } else if (remoteMessage === 'VOUCHER_NOT_YET_VALID') {
      errorMessage = 'Schade. Der Gutschein ist noch nicht gültig.';
    } else if (remoteMessage === 'VOUCHER_ALREADY_EXPIRED') {
      errorMessage = 'Schade. Der Gutschein ist leider nicht mehr gültig.';
    } else if (remoteMessage === 'VOUCHER_ONLY_ON_LOGGED_IN_USER') {
      errorMessage = 'Loggen sie sich bitte ein und geben sie den Gutschein erneut ein.';
    } else if (remoteMessage === 'VOUCHER_ALREADY_REDEEMED') {
      errorMessage = 'Schade. Der Gutschein wurde bereits eingelöst.';
    } else if (remoteMessage === 'VOUCHER_MAX_USAGE_REACHED') {
      errorMessage = 'Schade. Der Gutschein ist nicht mehr gültig. #2';
    } else if (remoteMessage === 'VOUCHER_USER_FALSE') {
      errorMessage = 'Schade. Sie können diesen Gutschein nicht nutzen.';
    } else if (remoteMessage === 'VOUCHER_ALREADY_IN_BASKET') {
      errorMessage = 'Schade. Sie haben bereits einen Gutschein im Verkaufskorb. ' +
        'Nutzen sie diesen Gutschein bei einem späteren Verkauf.';
    }
    return errorMessage;
  }

  public getRatingList(type: string, maxItems: number = 20, page: number = 1) {
    const path = '/api/web/purchase/ratings/' + type + '/' + parseInt(String(maxItems), 10) + '/' + parseInt(String(page), 10);

    return this.http
      .get<RatingListResponse>((Configuration.getApiBaseUrl() + path));
  }


  getRatingKey(type, page, maxResults) {
    return 'P_RATINGS_' + type + '_' + page + '_' + maxResults;
  }
}


