import { Observable, of, Observer } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class KeyDownEventService {
    private keyDown: Observable<any>;;
    private keyDownObserver: Observer<string>;

    constructor() {

        this.keyDown = Observable.create((observer: Observer<string>) => {
            console.log('creating Observer');
            this.keyDownObserver = observer;
        });
    }


    public isKeyDown(key) {
        if (this.keyDownObserver) {
            this.keyDownObserver.next(key);
        }
    }


    public getKeyDown() {
        return this.keyDown;
    }

}