import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/purchase/purchase.module').then(mod => mod.PurchaseModule)
  }
  , {
    path: 'benutzer',
    loadChildren: () => import('./modules/user/user.module').then(mod => mod.UserModule)
  }
  , {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)
  }
  , {
    path: 'seiten',
    loadChildren: () => import('./modules/static-page/static-page.module').then(mod => mod.StaticPageModule)
  }
  , {
    path: 'newsletter',
    loadChildren: () => import('./modules/newsletter/newsletter.module').then(mod => mod.NewsletterModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled'
  }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
