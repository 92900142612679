export class Configuration {
    public static config = {
        apiProtocol: 'https',
        apiHost: 'api.bücherverkaufen.de',
        gaTrackingID: 'UA-172318000-1'

    };

    public static get(name: string): string {

        if (typeof this.config[name] === 'undefined') {
            throw new Error('Unknown Config Parameter "' + name + '"');
        }
        return this.config[name];
    }

    public static getApiBaseUrl(): string {
        return this.get('apiProtocol') + '://' + this.get('apiHost');
    }
}
