import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArticleTmp, BasketTmp } from '../../interfaces/purchase.interfaces';
import { PurchaseService } from '../../service/purchase.service';
import { MatDialog } from '@angular/material/dialog';
import { VirtualTimeScheduler } from 'rxjs';
import { PurchaseDialogComponent } from '../purchase-dialog/purchase-dialog.component';

@Component({
  selector: 'app-purchase-article',
  templateUrl: './purchase-article.component.html',
  styleUrls: ['./purchase-article.component.scss']
})
export class PurchaseArticleComponent implements OnInit {

  @Input() article: ArticleTmp;

  @Output() basketHasChanged = new EventEmitter<BasketTmp>();
  
  imgLoaded: boolean;

  constructor(
    private purchaseService: PurchaseService,
    public dialog: MatDialog ) {}


  ngOnInit() {
    this.imgLoaded = false;
  }



  public deleteArticle() {
    console.log('delete');
    const observableDeleteResponse = this.purchaseService.deleteArticleTmp(this.article.id);
    observableDeleteResponse.subscribe(
      res => {
        let purchaseResult: any;
        purchaseResult = res;
        console.log('HTTP response', res);
        if (purchaseResult.success) {
          const basketTmp = purchaseResult.basket as BasketTmp;

          this.basketHasChanged.emit(basketTmp);
          console.log('new basket emitted.');
        }
      },
      err => console.log('HTTP Error', err),
      () => console.log('HTTP request completed.')
    );
  }

  public openNoPurchaseDialog() {
    this.dialog.open(PurchaseDialogComponent);
  }
  
  setImageLoaded() {
    this.imgLoaded = true;
  }

}
