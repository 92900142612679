import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private baseApiUrl: string;

  private baseConfig: any;

  constructor(private http: HttpClient) {
    this.baseApiUrl = Configuration.getApiBaseUrl();
  }

  getRemoteHtml(token: string) {
    const path = '/api/web/content/html-text/' + token;
    return this.http
      .get<HtmlTextResponse>((this.baseApiUrl + path));
  }

  getBaseConfig() {
    const path = '/api/web/conf/site-base';
    return this.http
      .get<BaseConfigResponse>((this.baseApiUrl + path));
  }

  getPurchaseCounts() {
    const path = '/api/web/purchase/purchase-counts';
    return this.http
      .get<PurchaseCountsResponse>((this.baseApiUrl + path));
  }

}

export interface HtmlTextResponse {
  htmlText: string;
}

export interface BaseConfigResponse {
  config: any;
}

export interface PurchaseCountsResponse {
  success: boolean;
  count: number;
}