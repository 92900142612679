import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from '../../service/base.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { LocalStorageService } from '../../storage/storage.service';

@Component({
  selector: 'app-base-remote-html',
  templateUrl: './remote-html.component.html',
  styleUrls: ['./remote-html.component.scss']
})
export class RemoteHtmlComponent implements OnInit {

  @Input() token: string;

  public htmlText = '';

  constructor(
    private state: TransferState,
    private baseService: BaseService,
    private storage: LocalStorageService
  ) { }

  ngOnInit() {

    const STATE_KEY = makeStateKey(this.token);
    const storageKey = 'RemoteHtml_' + this.token;

    this.htmlText = this.state.get(STATE_KEY, null);
    if (!this.htmlText) {
      if (this.storage.getCached(storageKey)) {
        this.htmlText = this.storage.getCached(storageKey);
        console.log('RemoteHtml from storage');
      } else {
        this.baseService.getRemoteHtml(this.token)
          .subscribe(
            (htmlTextResponse) => {
              this.htmlText = htmlTextResponse.htmlText;
              this.state.set(STATE_KEY, this.htmlText);
              this.storage.storeCached(storageKey, this.htmlText, 1200);
            }
          );
        console.log('RemoteHtml from backend');
      }


    } else {
      console.log('RemoteHtml from state');
    }

  }

}


