import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminService, Rating } from 'src/app/modules/admin/service/admin/admin.service';

@Component({
  selector: 'app-shared-rating-item',
  templateUrl: './rating-item.component.html',
  styleUrls: ['./rating-item.component.scss']
})
export class RatingItemComponent implements OnInit {

  @Input() ratingItem: Rating;
  @Input() isAdmin = false;

  public responseForm: FormGroup;

  public message: string;
  public errorMessage: string;

  public edit = false;

  public ratingSections = [
    { head: 'Gesamtnote', field: 'rateGeneral' },
    { head: 'Bedienbarkeit', field: 'rate1' },
    { head: 'Geschwindigkeit', field: 'rate2' },
    { head: 'Kommunikation und Service', field: 'rate3' },
    { head: 'Ankaufspreise', field: 'rate4' }
  ];

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.responseForm = new FormGroup({
      response: new FormControl('')
    });
    this.responseForm.get('response').setValue(this.ratingItem.noteResponse ? this.ratingItem.noteResponse : '');
  }

  storeResponse() {
    const response = this.responseForm.get('response').value;
    this.adminService.setRatingResponse(this.ratingItem.purchaseId, response).subscribe(
      (data) => {
        console.log(data);
        if (data.success) {
          this.ratingItem.noteResponse = response;
          this.errorMessage = null;
          this.message = "Daten wurden gespeichert.";
          this.edit = false;
        } else {
          this.message = null;
          this.errorMessage = "Ein Fehler ist aufgetreten. :( ";
        }
      },
      (error) => {
        console.log(error);
        this.errorMessage = "Ein Fehler ist aufgetreten. :( ";
      }
    );
  }

}
