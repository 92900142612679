import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { ArticleComponent } from '../admin/components/article/article.component';
import { PurchaseArticleComponent } from '../purchase/components/article/purchase-article.component';
import { MustMatchDirective } from '../base/directive/must-match/must-match.directive';
import { RemoteHtmlComponent } from '../base/components/remote-html/remote-html.component';
import { EscapeHtmlPipe } from '../base/pipe/keep-html.pipe';
import { LocalStorageService } from '../base/storage/storage.service';
import { UserService } from '../user/services/user/user.service';
import { AdminService } from '../admin/service/admin/admin.service';
import { RequestInterceptor } from '../base/interceptors/request.interceptor';
import { ResponseInterceptor } from '../base/interceptors/response.interceptor';
import { LogoutComponent } from '../user/auth/logout/logout.component';
import { LoginFormComponent } from '../user/forms/login-form/login-form.component';
import { LoginComponent } from '../user/auth/login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TruncateTextPipe } from '../base/pipe/truncateText.pipe';
import { BaseService } from '../base/service/base.service';
import { GtagService } from '../../service/google/gtag.service';
import { LogService } from 'src/app/service/log/log.service';
import { RatingItemComponent } from './components/rating-item/rating-item.component';
import { RatingTotalsComponent } from './components/rating-totals/rating-totals.component';
import localeDE from '@angular/common/locales/de';
import { MatPaginatorIntlDE } from './extra/mat-paginator-intl-de';
import { RouterModule } from '@angular/router';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RatingModule } from 'ng-starrating';
import { JwPaginationComponent } from 'jw-angular-pagination';

registerLocaleData(localeDE);
@NgModule({
  declarations: [
    PurchaseArticleComponent,
    ArticleComponent,
    MustMatchDirective,
    EscapeHtmlPipe,
    RemoteHtmlComponent,
    LoginComponent,
    LoginFormComponent,
    LogoutComponent,
    TruncateTextPipe,
    RatingItemComponent,
    RatingTotalsComponent
  ],
  imports: [
    ShowHidePasswordModule,
    // ExtraModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    MatPaginatorModule,
    FlexLayoutModule,
    MatCardModule,
    MatDialogModule,
    LayoutModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    RouterModule,
    RatingModule
  ],
  exports: [
    ArticleComponent,
    LoginComponent,
    LoginFormComponent,
    LogoutComponent,
    PurchaseArticleComponent,
    RatingItemComponent,
    RatingTotalsComponent,
    MustMatchDirective,
    EscapeHtmlPipe,
    RemoteHtmlComponent,
    ShowHidePasswordModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatButtonModule,
    MatSelectModule,
    MatButtonModule,
    MatPaginatorModule,
    FlexLayoutModule,
    LayoutModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatCardModule,
    MatTabsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    TruncateTextPipe,
    RatingModule,
    //JwPaginationComponent
  ],
  providers: [
    LocalStorageService, UserService, AdminService, BaseService, GtagService, LogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlDE,
    }
  ]
})
export class SharedModule { }

