import { Component, Inject, OnInit } from '@angular/core';
import { BaseService } from '../../service/base.service';
import { LocalStorageService } from '../../storage/storage.service';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';


@Component({
  selector: 'app-base-purchase-count',
  templateUrl: './purchase-count.component.html',
  styleUrls: ['./purchase-count.component.scss']
})
export class PurchaseCountComponent implements OnInit {

  public currentCountResult: number;

  constructor(
    private storage: LocalStorageService,
    private baseService: BaseService,
    @Inject(PLATFORM_ID) private platformId,
    private transferState: TransferState
  ) { }

  ngOnInit() {

    const storageKey = 'PURCHASE_COUNTS';

    if (this.storage.getCached(storageKey)) {
      this.currentCountResult = this.storage.getCached(storageKey);
      console.log('CountResult from storage');
      return;
    }
    console.log('transferState: ', this.transferState);
    const KEY = makeStateKey<number>('PurchaseCount');
    
    this.currentCountResult = this.transferState.get<number>(KEY, null);
    if (this.currentCountResult) {
      console.log('CountResult from backend!');
      this.storage.storeCached(storageKey, this.currentCountResult, 200);
    } else {
      this.baseService.getPurchaseCounts(
      ).subscribe((data) => {
        if (data.success && data.count) {
          this.currentCountResult = data.count;
          this.storage.storeCached(storageKey, this.currentCountResult, 200);
          if (isPlatformServer(this.platformId)) {
            this.transferState.set(KEY, this.currentCountResult);
          }
          console.log('CountResult from backend!');
        }
      });
    }
  }

}
