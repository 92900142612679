import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AuthService } from 'src/app/service/auth/auth.service';
import { AuthGuard } from 'src/app/service/auth/authguard.service';
import { BasketService } from 'src/app/service/basket/basket.service';
import { TitleMetaService } from 'src/app/service/title-meta/title-meta.service';
import { NavigationComponent } from './modules/base/navigation/navigation.component';
import { RootComponent } from './modules/base/root/root.component';
import { CookieDialogComponent } from './modules/base/components/cookie-dialog/cookie-dialog.component';
import { PurchaseCountComponent } from './modules/base/components/purchase-count/purchase-count.component';
import { SharedModule } from './modules/shared/shared.module';
import { RequestInterceptor } from './modules/base/interceptors/request.interceptor';
import { UserService } from './modules/user/services/user/user.service';
import { AdminService } from './modules/admin/service/admin/admin.service';
import { LocalStorageService } from './modules/base/storage/storage.service';
import { PurchaseService } from './modules/purchase/service/purchase.service';
import { LoginComponent } from './modules/user/auth/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { PurchaseDialogComponent } from './modules/purchase/components/purchase-dialog/purchase-dialog.component';
import { MatDialogRef, MatDialog, MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    NavigationComponent,
    RootComponent,
    CookieDialogComponent,
    PurchaseCountComponent,
    PurchaseDialogComponent
  ],
  imports: [
    //ExtraModule,
    CommonModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'MREBUY' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    MatDialogModule,

  ],
  exports: [
    CommonModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [
    {
       provide: HTTP_INTERCEPTORS,
       useClass: RequestInterceptor,
       multi: true
     } ,
     {
      provide: MatDialogRef,
      useValue: {}
    },
    UserService, AdminService, LocalStorageService,  AuthService,
    PurchaseService, BasketService, AuthGuard, TitleMetaService
  ],
  bootstrap: [RootComponent],
  entryComponents: [LoginComponent, CookieDialogComponent, PurchaseDialogComponent]
})
export class AppModule { }
