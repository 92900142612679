import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/config/configuration';
import { BasketSoldListResponse, LoginResponse, UserDataResponse } from 'src/app/models/user/userResponses';

import { Observable } from 'rxjs';
import { RoleTypes } from 'src/app/models/role';
import { RatingActResult } from '../../model/user.model';

@Injectable()
export class UserService {


  private baseApiUrl: string;

  constructor(private http: HttpClient) {
    this.baseApiUrl = Configuration.getApiBaseUrl();
  }

  public checkEmailNotTaken(email: string) {
    const path = '/api/web/auth/email-exist';
    return this.http
      .post((this.baseApiUrl + path), { email: email });
  }

  public register(userData) {
    const path = '/api/web/auth/register';
    return this.http
      .post((this.baseApiUrl + path), { userData });
  }

  public forgetPassword(email) {
    const path = '/api/web/auth/password-forget';
    return this.http
      .post((this.baseApiUrl + path), { email: email });
  }

  public resetPassword(password, token) {
    const path = '/api/web/auth/password-reset';
    return this.http
      .post((this.baseApiUrl + path), { token: token, password: password });
  }

  public login(userData) {
    const path = '/api/web/auth/login/' + RoleTypes.Seller.toLowerCase();
    return this.http
      .post<LoginResponse>((this.baseApiUrl + path), { userData });
  }

  public getPurchaseList(page: number) {
    const path = '/api/web/user/basket-sold-list';
    return this.http
      .get<BasketSoldListResponse>((this.baseApiUrl + path), {});
  }

  public getPurchase(basketId: number) {
    const path = '/api/web/user/basket-sold/' + basketId;
    return this.http
      .get<any>((this.baseApiUrl + path), {});
  }

  public getDocument(uri: string): Observable<Blob> {
    return this.http.get(uri, { responseType: 'blob' });
  }

  public validate(token: string) {
    const path = '/api/web/auth/validate';
    return this.http
      .post<any>((this.baseApiUrl + path), { token: token });
  }


  public getData() {
    const path = '/api/web/user/data';
    return this.http
      .get<UserDataResponse>((this.baseApiUrl + path));
  }

  // update
  public updateAddress(data) {
    const path = '/api/web/user/update/address';
    return this.http
      .post((this.baseApiUrl + path), { address: data });
  }

  public updatePassword(data) {
    const path = '/api/web/user/update/password';
    return this.http
      .post((this.baseApiUrl + path), { password: data });
  }

  public updatePayout(data) {
    const path = '/api/web/user/update/payout';
    return this.http
      .post((this.baseApiUrl + path), { payout: data });
  }

  public updatePerson(data) {
    const path = '/api/web/user/update/person';
    return this.http
      .post((this.baseApiUrl + path), { person: data });
  }

  public getPurchaseRating(token) {
    const path = '/api/web/purchase/purchase-rating/' + token;
    return this.http
      .get((this.baseApiUrl + path));
  }

  public putPurchaseRating(token, rating: RatingActResult) {
    const path = '/api/web/purchase/purchase-rating/' + token;
    return this.http
      .post((this.baseApiUrl + path), {rating});
  }
}



