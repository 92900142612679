import { Inject, Injectable } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Observable } from 'rxjs';


// key that is used to access the data in local storage
const STORAGE_KEY = 'app_data';

@Injectable()
export class LocalStorageService {

    constructor(@Inject(PLATFORM_ID) private platformId: object) { }

    public store(key: string, value: any): boolean {

        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, JSON.stringify(value));
        }
        if (isPlatformServer(this.platformId)) {
            // Server only code.
        }

        return true;
    }

    public get(key: string): any {

        if (isPlatformBrowser(this.platformId)) {
            return JSON.parse(localStorage.getItem(key));
        }

        return null;
    }


    public remove(key: string) {

        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        }
        return true;
    }


    public storeCached(key: string, value: any, seconds: number): boolean {

        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(key, JSON.stringify({ v: value, sec: seconds, savedAt: new Date().getTime() }));
        }
        if (isPlatformServer(this.platformId)) {
            // Server only code.
        }

        return true;
    }

    public getCached(key: string): any {
        const time = new Date();
        if (isPlatformBrowser(this.platformId)) {
            const item = JSON.parse(localStorage.getItem(key));
            if (item) {
                const savedAt = item.savedAt;
                const seconds = item.sec;
                const progress = Math.abs(new Date().getTime() - savedAt);
                console.log(progress, seconds)
                if (progress <= seconds * 1000) {
                    return item.v;
                }
            }

        }

        return null;
    }


    public removeCached(key: string) {

        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        }
        return true;
    }

}