import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '../storage/storage.service';
import { AuthService } from 'src/app/service/auth/auth.service';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private storage: LocalStorageService, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const authReq = req.clone({
            headers: new HttpHeaders({
                'Authorization': '' + this.storage.get('Authorization'),
                'X-Basket-Token': '' + this.storage.get('X-Basket-Token'),
                'X-Longtime-Token': '' + (this.authService.currentUserValue ? this.authService.currentUserValue.token :  ''),
            })
        });

        console.log('Intercepted HTTP call', authReq.headers.keys());

        return next.handle(authReq);
    }
}
