import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/config/configuration';
import { LocalStorageService } from 'src/app/modules/base/storage/storage.service';

@Injectable(
    { providedIn: 'root' }
)
export class LogService {

    private baseApiUrl: string;

    constructor(private http: HttpClient, private localStorage: LocalStorageService) {
        this.baseApiUrl = Configuration.getApiBaseUrl();
    }

    log(module: string, message: string, type = 'info') {
        const path = '/api/web/content/logging';
        this.http
            .post<any>((Configuration.getApiBaseUrl() + path), { module, message, type }).subscribe(
                (data) => {},
                (error) => {}
            );
    }
}