import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { RoleTypes } from 'src/app/models/role';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) {
        console.log('authGuard constr');
     }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.data.role) {
            const currentUser = this.authService.currentUserValue;
            console.log(route, currentUser);

            // check if route is restricted by role
            if (currentUser && route.data.role === currentUser.role) {
                // authorised so return true
                return true;
            } else {
                console.log('role', route.data.role);
                // authorised so return true
                switch (route.data.role) {

                    case RoleTypes.Seller: {
                        this.router.navigate(['/benutzer/login']);
                        break;
                    }

                    case RoleTypes.Admin: {
                        this.router.navigate(['/admin/login']);
                        break;
                    }
                }
                // not authorised so return true
                return false;
            }

            // not authorised so return true
            return false;

        }
        // authorised so return true
        return true;


    }
}