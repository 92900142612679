import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-logout',
  template: ''
})
export class LogoutComponent implements OnInit {



  constructor(
    private title: Title,
    private authService: AuthService,
    private router: Router,
    location: Location
  ) {
  }

  ngOnInit() {
    this.title.setTitle('Abmelden');
    const currRole = this.authService.currentUserValue.role;
    this.authService.logout();

    if (currRole === 'ADMIN') {
      this.router.navigate(['/admin/login']);
    } else {
      this.router.navigate(['/benutzer/login']);
    }
  }




}