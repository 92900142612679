import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasketTmp } from 'src/app/modules/purchase/interfaces/purchase.interfaces';
import { PurchaseService } from 'src/app/modules/purchase/service/purchase.service';



@Injectable(
    { providedIn: 'root' }
)
export class BasketService {

    private currentBasketSubject: BehaviorSubject<BasketTmp>;
    public currentBasket: Observable<BasketTmp>;

    constructor(private purchaseService: PurchaseService) {
        this.currentBasketSubject = new BehaviorSubject<BasketTmp>(null);
        this.currentBasket = this.currentBasketSubject.asObservable();
        console.log('BasketService constr');
        this.init();
    }

    public get currentBasketValue(): BasketTmp {
        return this.currentBasketSubject.value;
    }

    public setBasket(basket: BasketTmp) {
        this.currentBasketSubject.next(basket);
        return this;
    }

    public clear() {
        this.currentBasketSubject.next(null);
    }

    public init() {
        this.purchaseService.getBasket().subscribe(
            res => {
                let purchaseResult: any;
                purchaseResult = res;
                if (purchaseResult.success) {

                    const basketTmp = purchaseResult.basket as BasketTmp;
                    this.setBasket(basketTmp);

                }
            }
        );
    }
}
