import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/config/configuration';
import { RoleTypes } from 'src/app/models/role';
import { LoginResponse, BasketSoldResponse, SuccessResponse } from 'src/app/models/user/userResponses';
import { BasketResponse } from '../../interface/basketInterface';
import { AdminUserResult, AdminUser, ToPayingOutsResponse } from '../../model/models';
import { MailTextInterface } from '../../components/mail-templates/mail-templates.component';
import { Block } from '../../components/basket/basket.component';
import { Observable } from 'rxjs';
import { HistoryData } from '../../components/param-history-table/param-history-table.component';

@Injectable()
export class AdminService {

  private baseApiUrl;

  constructor(private http: HttpClient) {
    this.baseApiUrl = Configuration.getApiBaseUrl();
  }

  public login(userData) {
    const path = '/api/web/auth/login/' + RoleTypes.Admin.toLowerCase();

    return this.http
      .post<LoginResponse>((Configuration.getApiBaseUrl() + path), { userData });
  }

  public getBasket(id, type = 'sold') {
    const path = '/api/web/admin/basket/' + type + '/' + id;

    return this.http
      .get<BasketResponse>((Configuration.getApiBaseUrl() + path));
  }

  public cancelBasket(id) {
    const path = '/api/web/admin/basket/sold/' + id + '/cancel';

    return this.http
      .post<BasketResponse>((Configuration.getApiBaseUrl() + path), {});
  }

  public getArticle(id, type = 'sold') {
    const path = '/api/web/admin/artilce/' + type + '/' + id;

    return this.http
      .get<BasketResponse>((Configuration.getApiBaseUrl() + path));
  }

  public confirmReceiptBasket(id) {
    const path = '/api/web/admin/basket/confirm-receipt/' + id;

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), []);
  }

  // holt alle NichtAnkaufGründe vom Server
  public getRejectionReasons() {
    const path = '/api/web/admin/rejection-reasons';

    return this.http
      .get((Configuration.getApiBaseUrl() + path));
  }

  // speichert ob Artikel angekauft oder nicht auf dem Server
  public setIsPurchased(idArticle: string | number, isPurchasedParam: boolean, reasonParam: string = null) {
    console.log(idArticle);
    const path = '/api/web/admin/basket/purchase-article/' + idArticle;

    return this.http
      .post<SuccessResponse>((Configuration.getApiBaseUrl() + path), { isPurchased: isPurchasedParam, reason: reasonParam });
  }

  // schließt processing ab
  public setProcessingEnd(idBasket: string | number) {
    const path = '/api/web/admin/basket/processing-end/' + idBasket;

    return this.http
      .post<SuccessResponse>((Configuration.getApiBaseUrl() + path), {});
  }

  // holt vieable tabellen
  public getViewableTables(tableName: string = null) {
    let path = '';
    if (tableName) {
      path = '/api/web/admin/super/viewable-tables/' + tableName;
    } else {
      path = '/api/web/admin/super/viewable-tables';
    }

    return this.http
      .get<any>((Configuration.getApiBaseUrl() + path), {});
  }

  // holt Adminbenutzer
  public getUsers() {

    const path = '/api/web/admin/super/users';

    return this.http
      .get<AdminUserResult>((Configuration.getApiBaseUrl() + path), {});
  }

  // erstellt neuen Benutzer
  public createUser(user: AdminUser) {

    const path = '/api/web/admin/super/users/new';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { user });
  }

  public updateUser(user: AdminUser, what: string) {

    const path = '/api/web/admin/super/users/update';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { userData: user, action: what });
  }

  public getPayingOuts() {
    const path = '/api/web/admin/super/to-paying-out';

    return this.http
      .get<ToPayingOutsResponse>((Configuration.getApiBaseUrl() + path));
  }

  public block(blocked: Block) {
    const path = '/api/web/admin/block-item';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { block: blocked });
  }

  public setPayingOuts(payingOutBasketIds) {
    const path = '/api/web/admin/super/set-paying-out';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { payingOuts: payingOutBasketIds });
  }

  // in dialog

  public getCSVPayingOuts( definer: string ): Observable<Blob> {
    const path = '/api/web/admin/super/export-paying-out-data/' + definer;
    return this.http.get((Configuration.getApiBaseUrl() + path),  { responseType: 'blob' });
  }


  public confirmExportPayingOuts( ) {
    const path = '/api/web/admin/super/confirm-paying-out-export';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }

  public beforeExportPayingOuts( ) {
    const path = '/api/web/admin/super/before-paying-out-export';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }

  public getPayingOutExportStatistic( ) {
    const path = '/api/web/admin/super/get-paying-out-export-statistic';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }


  // end dialog

  public findSeller(sellerData: any) {
    const path = '/api/web/admin/seller/find';

    return this.http
      .post<SellerResponse>((Configuration.getApiBaseUrl() + path), { sellerData });
  }

  public deleteSeller(sellerData: any) {
    const path = '/api/web/admin/seller/delete';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { sellerData });
  }

  public updateParam(paramData: ParamData) {
    const path = '/api/web/admin/params/update';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { paramData });
  }

  public getTemplateList() {
    const path = '/api/web/admin/mail/list';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), {});
  }

  public getTemplate(token: string) {
    const path = '/api/web/admin/mail/item';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { token });
  }

  public updateTemplate(templateText: MailTextInterface) {
    const path = '/api/web/admin/mail/update';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { templateText });
  }

  public testTemplate(templateText: MailTextInterface) {
    const path = '/api/web/admin/mail/test';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { templateText });
  }

  // Webtexts

  public getWebTextList() {
    const path = '/api/web/admin/web-texts/list';

    return this.http
      .post<WebtextListResponse>((Configuration.getApiBaseUrl() + path), {});
  }

  public getWebText(id: string) {
    const path = '/api/web/admin/web-texts/item';

    return this.http
      .post<WebtextItemResponse>((Configuration.getApiBaseUrl() + path), { id });
  }

  public updateWebText(id: string, html: string) {
    const path = '/api/web/admin/web-texts/update';

    return this.http
      .post<WebtextUpdateResponse>((Configuration.getApiBaseUrl() + path), { id, html });
  }

  public getRatingList(type: string, maxItems: number = 20, page: number = 1) {
    const path = '/api/web/admin/ratings/' + type + '/' + parseInt(String(maxItems), 10) + '/' + parseInt(String(page), 10);

    return this.http
      .get<RatingListResponse>((Configuration.getApiBaseUrl() + path));
  }

  public setRatingResponse(ratingId: string, response: string) {
    const path = '/api/web/admin/rating-response';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { ratingId, response });
  }

  public setNoPayingOut(basketId: string) {
    const path = '/api/web/admin/super/set-no-paying-out';

    return this.http
      .post<any>((Configuration.getApiBaseUrl() + path), { basketId });
  }

  public getChartsData() {
    const path = '/api/web/admin/charts-data';

    return this.http
      .get<any>((Configuration.getApiBaseUrl() + path));
  }

  public getSellerProfileData(sellerId: number) {
    const path = '/api/web/admin/seller/profile/' + sellerId;

    return this.http
      .post<SellerProfileData>((Configuration.getApiBaseUrl() + path), {});
  }


  public getParamhistoryData() {
    const path = '/api/web/admin/param-history-data';

    return this.http
      .get<HistoryData[]>((Configuration.getApiBaseUrl() + path));
  }
}

export interface Seller {
  id: number;
  name: string;
  email: string;
}

export interface SellerResponse {
  success: boolean;
  sellers: Array<Seller>;
}

export interface ParamData {
  id: string;
  updateValue: string;
  scenario: string;
}

export interface WebtextListItem {
  id: string;
  description: string;
}
export interface WebtextItem {
  id: string;
  html: string;
}
export interface WebtextListResponse {
  success: boolean;
  list: Array<WebtextListItem>;
}

export interface WebtextItemResponse {
  success: boolean;
  webtext: WebtextItem;
}


export interface WebtextUpdateResponse {
  success: boolean;
  message: string;
}

export interface RatingListResponse {
  success: boolean;
  ratings: Array<Rating>;
  totalCount: number;
  totals: Array<RatingTotal>;
}

export interface Rating {
  purchaseId: string;
  seller: string;
  sellerId?: string;
  rateGeneral: string;
  rate1: string;
  rate2: string;
  rate3: string;
  rate4: string;
  note: string;
  noteResponse: string;
  location: string;
  soldAt: string;
  statusDate: string;
}

export interface RatingTotal {
  ratingDimensionName: string;
  ratingCount30d: number;
  ratingCount90d: number;
  ratingCount180d: number;
  ratingCount360d: number;
  ratingCountTotal: number;
  ratingDimensionValue30d: number;
  ratingDimensionValue90d: number;
  ratingDimensionValue180d: number;
  ratingDimensionValue360d: number;
  ratingDimensionValueTotal: number;
  ratingDimensionPoints30d: number;
  ratingDimensionPoints90d: number;
  ratingDimensionPoints180d: number;
  ratingDimensionPoints360d: number;
  ratingDimensionPointsTotal: number;
  ratingUpdate: string;
}

export interface SellerProfileData {
  seller: {id: number; name: string};
  sellerProfile: {
      name: string; value: string; unit: string; lastUpdate: string
  };
}
