import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LogService } from 'src/app/service/log/log.service';
import { LocalStorageService } from '../../storage/storage.service';

@Component({
  selector: 'app-cookie-dialog',
  templateUrl: './cookie-dialog.component.html',
  styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieDialogComponent implements OnInit {

  public showChoicesCont = false;
  public showInfo = false;
  public allowMarketingCookies = true;

  constructor(
    public dialogRef: MatDialogRef<CookieDialogComponent>,
    private localStorage: LocalStorageService,
    private logService: LogService
  ) { }

  showChoices(): void {
    this.showChoicesCont = true;

    //  this.dialogRef.close();
  }

  acceptAll(): void {
    this.allowMarketingCookies = true;
    this.localStorage.store('allowMarketingCookies', this.allowMarketingCookies);
    this.localStorage.store('hasCookiesSelected', true);
    this.dialogRef.close();
  }

  acceptChoices() {

    this.localStorage.store('allowMarketingCookies', this.allowMarketingCookies);
    this.localStorage.store('hasCookiesSelected', true);
    if (!this.allowMarketingCookies) {
      this.logService.log('cookie', 'MarketingCookie not permission given', 'info');
    }
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}