import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, Route } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class TitleMetaService {
    constructor(private router: Router, private titleService: Title, private metaService: Meta) {
        router.events.subscribe(
            e => {
                if (e instanceof RouteConfigLoadEnd) {
                    this.titleService.setTitle(e.route.data.title || 'Bücher verkaufen - nachen Sie ihre Bücher zu Geld.');
                    if (e.route.data.description) {
                        this.metaService.addTag({ name: 'descriptiom', content: e.route.data.description });
                    }
                    this.setOthers(e.route);
                }
            }
        );


    }

    setOthers(route: Route) {

    }
}
