import { Component, OnInit, AfterViewInit, HostListener, Inject, PLATFORM_ID, APP_ID, ElementRef } from '@angular/core';
import { Location, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { KeyDownEventService } from 'src/app/service/key/keyDownEvent.service';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../storage/storage.service';
import { CookieDialogComponent } from '../components/cookie-dialog/cookie-dialog.component';
import { GtagService } from 'src/app/service/google/gtag.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']

})
export class RootComponent implements OnInit, AfterViewInit {

  private route: string;

  public section: string;

  hasCookiesSelected = false;

  public allowedMarketingCookies = false;


  constructor(
    location: Location,
    router: Router,
    private keyDownEventService: KeyDownEventService,
    public dialog: MatDialog,
    private localStorage: LocalStorageService,
    private gtagService: GtagService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    @Inject(DOCUMENT) private document,
    private elementRef: ElementRef,
  ) {

    router.events.subscribe(val => {

      this.route = location.path();
      const routeParts = this.route.split('/');

      if (routeParts.length > 1 && routeParts[1] && routeParts[1] === 'admin') {
        this.section = 'admin';
      } else {
        this.section = 'seller';
      }

    });
  }


  ngAfterViewInit() {

    if (isPlatformBrowser(this.platformId)) {
      if (!this.hasCookiesSelected) {
        this.openDialog();
      } else {
        if (this.allowedMarketingCookies) {
          this.gtagService.init();
          this.addFB();
        }
      }
    }
  }

  ngOnInit() {

    this.hasCookiesSelected = this.localStorage.get('hasCookiesSelected');
    this.hasCookiesSelected = this.localStorage.get('hasCookiesSelected');
    this.allowedMarketingCookies = this.localStorage.get('allowMarketingCookies');
  }

  @HostListener('document:keydown', ['$event']) onKeyDown(e) {
    // console.log('event keydown', e);
    this.keyDownEventService.isKeyDown(e);
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CookieDialogComponent, {
      width: '90%', maxWidth: '700px', disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      this.allowedMarketingCookies = this.localStorage.get('allowMarketingCookies');
      if (this.allowedMarketingCookies) {

        this.gtagService.init();
        this.addFB();

      }
    });
  }

  addFB() {
    const scriptElementFB = this.document.createElement('script');
    // tslint:disable-next-line: max-line-length
    const fbFunction = '!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,\'script\',\'https://connect.facebook.net/en_US/fbevents.js\');fbq(\'init\', \'789972588424550\');fbq(\'track\', \'PageView\');';
    scriptElementFB.text = fbFunction;
    this.elementRef.nativeElement.appendChild(scriptElementFB);
  }
}
