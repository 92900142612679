import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

/**
 * @title Dialog elements
 */
 @Component({
  selector: 'purchase-content-dialog',
  templateUrl: './purchase-content-dialog.html',
})
export class PurchaseDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<PurchaseDialogComponent>) {}

  ngOnInit() {

  }
}
