import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, tap, catchError } from 'rxjs/operators';
import { LocalStorageService } from '../storage/storage.service';
import { AuthService } from 'src/app/service/auth/auth.service';


@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(private storage: LocalStorageService, private authService: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // extend server response observable with logging
        return next.handle(req).pipe(
            // proceed when there is a response; ignore other events
            filter(event => event instanceof HttpResponse),
            map(
                (event: HttpResponse<any>) => {
                    console.log('he ein event.', event);
                    this.handleHeaders(event);
                    return event;
                },
                // Operation failed; error is an HttpErrorResponse
                error => { 
                    console.log(error);
                    if ([401, 403].indexOf(error.status) !== -1) {
                        this.authService.logout();
                    }
                }
            )
        );
    }

    /**
     * 
     * @param resp : HttpResponse
     */
    handleHeaders(resp) {
        this.storage.store('Authorization', resp.headers.get('Authorization'));
        this.storage.store('X-Basket-Token', resp.headers.get('X-Basket-Token'));
        this.storage.store('X-Longtime-Token', resp.headers.get('X-Longtime-Token'));
    }

}



