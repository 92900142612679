import { Component, Input, OnInit } from '@angular/core';
import { RatingTotal } from 'src/app/modules/admin/service/admin/admin.service';

@Component({
  selector: 'app-shared-rating-totals',
  templateUrl: './rating-totals.component.html',
  styleUrls: ['./rating-totals.component.scss']
})
export class RatingTotalsComponent implements OnInit {

  constructor() { }
  @Input() isAdmin = false;
  @Input() ratingTotals: Array<RatingTotal>;
  ngOnInit() {
  }

}
