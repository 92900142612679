import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../user/auth/login/login.component';
import { AuthService } from 'src/app/service/auth/auth.service';
import { AuthUser } from 'src/app/models/user/authUser';
import { BasketService } from 'src/app/service/basket/basket.service';
import { BasketTmp } from '../../purchase/interfaces/purchase.interfaces';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { JwPaginationComponent } from 'jw-angular-pagination';

@Component({
  selector: 'app-base-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public loggedInUser: AuthUser | undefined;
  // private loggedInUserValue: AuthUser | undefined;

  public isAdmin = false;

  public isSeller = false;
  public currentBasketTnp: BasketTmp;

  public isBrowser = false;

  @Input() section: string;

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private basketService: BasketService,
    @Inject(PLATFORM_ID) private platformId,
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
  }



  ngOnInit() {
    
    this.authService.currentUser.subscribe(
      (res) => {
        console.log('CurrentUser Changed');
        this.loggedInUser = res;
        if (this.loggedInUser) {

          switch (this.loggedInUser.role) {
            case 'ADMIN':
              this.isSeller = false;
              this.isAdmin = true;
              break;
            case 'SELLER':
              this.isAdmin = false;
              this.isSeller = true;
          }

        } else {
          this.isSeller = false;
          this.isAdmin = false;
        }
      },
      (error) => {
        console.log('e', error);
      }

    );

    this.basketService.currentBasket.subscribe(
      (res) => {
        this.currentBasketTnp = res as BasketTmp;
      }
    )
  }

  openLoginDialog(): void {

    console.log(this.dialog);
    const dialogRef = this.dialog.open(LoginComponent, {
      // width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }



}
