import { Injectable, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { BaseService } from 'src/app/modules/base/service/base.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

declare var gtag: any;

@Injectable({
    providedIn: 'root'
})
export class GtagService {

    private confGApiKey: string;

    constructor(
        private router: Router,
        private baseService: BaseService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) private platformId: object
    ) {

    }

    public pushEvent(
        iEventCategory: string,
        iEventAction: string,
        iEventLabel: string = null,
        iEventValue: number = null) {
        try {
            gtag('event', iEventAction, {
                eventCategory: iEventCategory,
                eventLabel: iEventLabel,
                value: iEventValue
            });
        } catch (error) {
            // do nothing
        }

    }

    public init() {
        try {
            if (isPlatformBrowser(this.platformId)) {
                // Client only code.
                if (!this.confGApiKey) {
                    this.baseService.getBaseConfig().subscribe(
                        (data) => {
                            console.log('configurationdata', data);
                            if (data.config.gAnalyticsId) {
                                this.confGApiKey = data.config.gAnalyticsId;
                                this.buildGtag();
                            }
                        }
                    );
                } else {
                    this.buildGtag();
                }
            }
        } catch (error) {
            // do nothing
        }

    }

    private buildGtag() {
        this.listenForRouteChanges();
        const conf: object = {};

        try {

            const script1 = this.document.createElement('script');
            script1.async = true;
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.confGApiKey;
            this.document.head.appendChild(script1);

            const script2 = this.document.createElement('script');
            script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '` + this.confGApiKey + `', {'send_page_view': false});
          `;
            this.document.head.appendChild(script2);
            console.log('Build: gAnalytics');
        } catch (ex) {
            console.error('Error appending google analytics');
            console.error(ex);
        }
    }

    public pagePath(path) {
        try {
            gtag('config', this.confGApiKey, {
                'page_path': path
            });
        } catch (error) {
            // do nothing
        }
    }

    private listenForRouteChanges() {
        let section = null;
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                try {
                    const routeParts = event.urlAfterRedirects.split('/');

                    if (routeParts.length > 1 && routeParts[1] && routeParts[1] === 'admin') {
                        section = 'admin';
                    }
                    if (section !== 'admin') {
                        console.log('page_path_changed:', event.urlAfterRedirects);
                        gtag('config', this.confGApiKey, {
                            page_path: event.urlAfterRedirects,
                        });
                    }
                } catch (error) {
                    // do nothing
                }

            }
        });

    }
}
