import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthUser } from 'src/app/models/user/authUser';
import { LocalStorageService } from 'src/app/modules/base/storage/storage.service';

@Injectable(
    { providedIn: 'root' }
)
export class AuthService {

    private currentUserSubject: BehaviorSubject<AuthUser>;
    public currentUser: Observable<AuthUser>;

    constructor(private storage: LocalStorageService) {
        this.currentUserSubject = new BehaviorSubject<AuthUser>(this.storage.get('currentUser'));
        this.currentUser = this.currentUserSubject.asObservable();
        console.log('authservice constr');
    }

    public get currentUserValue(): AuthUser {
        return this.currentUserSubject.value;
    }

    login(user: AuthUser) {
        this.storage.store('currentUser', user);
        this.currentUserSubject.next(user);
        return user;
    }

    logout() {
        // remove user from local storage to log user out
        this.storage.remove('currentUser');
        this.storage.remove('Authorization');
        this.currentUserSubject.next(undefined);
    }


}
