import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Login } from 'src/app/models/user/login';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { LoginFormComponent } from '../../forms/login-form/login-form.component';
import { AuthUser } from 'src/app/models/user/authUser';
import { AuthService } from 'src/app/service/auth/auth.service';
import { RoleTypes } from 'src/app/models/role';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/service/admin/admin.service';



@Component({
  selector: 'app-user-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  loginFormGroup: FormGroup;

  public loginError: boolean;
  public loginErrorMessage: string;

  public afterValidation = false;
  public fromPurchase = false;
  public fromPasswordForget = false;

  public role: string = RoleTypes.Seller;

  login = new Login();

  constructor(
    private userService: UserService, private adminService: AdminService, private authService: AuthService,
    private dialogRef: MatDialog, private route: ActivatedRoute, private router: Router
  ) { }

  @ViewChild(LoginFormComponent, {static: false})
  private loginFormComponent: LoginFormComponent;

  ngOnInit() {
    this.loginFormGroup = new FormGroup({});
    // role aus data url - bestimmt welche api benutzt wird.
    this.route.data.subscribe(
      data => {
        if (data.role) { this.role = data.role; }
      });
    this.afterValidation = this.route.snapshot.queryParamMap.get('afterValidation') ? true : false;
    this.fromPurchase = this.route.snapshot.queryParamMap.get('fromPurchase') ? true : false;
    this.fromPasswordForget = this.route.snapshot.queryParamMap.get('fromPasswordForget') ? true : false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loginFormGroup.addControl('login', this.loginFormComponent.loginForm);
    }, 100);
  }

  onSubmit(loginData) {
    console.log('submitted');
    this.loginError = false;
    this.loginErrorMessage = '';
    this.loginFormGroup.markAllAsTouched();
    // console.log(this.loginFormGroup.errors);


    if (this.loginFormGroup.valid) {
      console.log('login');
      let service;

      if (this.role === RoleTypes.Seller) {
        service = this.userService;
      } else {
        service = this.adminService;
      }

      service.login(loginData)
        .subscribe(
          res => {
            console.log('HTTP response', res);
            if (res.success && res.user.name) {
              const authUser = new AuthUser();
              authUser.name = res.user.name;
              authUser.token = res.user.token;
              authUser.isSuper = (res.user.isSuper) ? res.user.isSuper : false;
              authUser.role = this.role;
              this.authService.login(authUser);
              this.dialogRef.closeAll();
              console.log('HTTP response', authUser);
              this.redirect(authUser);
            } else {
              this.loginError = true;
              this.loginErrorMessage = 'Email oder Passwort falsch.';
            }
          },
          err => {
            console.log('HTTP Error', err.error.authError);
            if (err.error.authError) {
              if (err.error.authError) {
                switch (err.error.authError) {
                  case 'INVALID_USER_OR_PASSWORD':
                    this.loginError = true;
                    this.loginErrorMessage = 'Email oder Passwort falsch.';
                    break;
                  case 'USER_NOT_FOUND':
                    this.loginError = true;
                    this.loginErrorMessage = 'Email oder Passwort falsch.';
                    break;
                  case 'NOT_ACTIVATED_USER':
                    this.loginError = true;
                    this.loginErrorMessage = 'Du hast deinen Zugang noch nicht bestätigt. Bitte schaue in dein E-Mail-Postfach.';
                }

              }
            }

          }
        );
    } else {
      console.log('loginError');
    }
  }

  redirect(authUser: AuthUser) {
    if (authUser.role === RoleTypes.Admin) {
      this.router.navigateByUrl('/admin')
    } else {
      this.router.navigateByUrl('/')
    }
  }

  routeClick(e, path) {
    e.stopPropagation();
    console.log(e);
    this.router.navigateByUrl(path);
    this.dialogRef.closeAll();
  }

}
