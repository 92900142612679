import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Login } from 'src/app/models/user/login';

@Component({
  selector: 'app-user-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  public login = new Login();
  public loginForm: FormGroup;

  @Input() loginError;
  @Input() loginErrorMessage;

  constructor() { }

  ngOnInit() {

    this.loginForm = new FormGroup({
      email: new FormControl(this.login.email, [
        Validators.required,
        Validators.email
      ]),
      password: new FormControl(this.login.password, [
        Validators.required
      ])
    });
  }

get email() { return this.loginForm.get('email'); }
get password() {return this.loginForm.get('password'); }

}
